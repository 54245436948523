<script setup>
import { useTemplateRef } from 'vue';
import { ButtonTypes } from '@@/components/Common//Button.vue';
import { getMaskImageStyle } from '@@/utils/CommonUtils';
import { scrollActiveElementIntoView } from '@@/utils/Components/ComponentUtils';

const props = defineProps({
  canShowChipActiveIcon: {
    type: Boolean,
    default: true,
  },
  canShowLockedIcon: {
    type: Boolean,
    default: true,
  },
  isAllAccess: {
    type: Boolean,
    default: undefined,
  },
  // When false only one chip may be selected.
  multiple: {
    type: Boolean,
    default: true,
  },
  noWrap: {
    type: Boolean,
    default: false,
  },
  // Array of strings or array of { label, value } objects.
  options: {
    type: Array,
    required: true,
  },
  shouldScrollActiveElementIntoView: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  // When this option value is selected all other options will be deselected. When a different
  // option is selected this value will be deselected.
  selectAllOptionValue: {
    type: String,
    default: null,
  },
  // Array of strings of option values that will be displayed as active chips.
  modelValue: {
    type: Array,
    default() { return []; },
  },
});

const $emit = defineEmits(['update:modelValue']);
const $style = useCssModule();
const list = useTemplateRef('list');
const listItems = useTemplateRef('listItems');

const buttonProps = computed(() => props.secondary
  ? { small: true, type: ButtonTypes.chipSecondary }
  : { type: ButtonTypes.chip });

const listClass = computed(() => props.noWrap
  ? `tw-whitespace-nowrap tw-overflow-x-scroll hide-scrollbar-on-mobile ${$style.list}`
  : null);

const listItemClass = computed(() => `tw-inline-block tw-mr-1.5 ${props.noWrap ? '' : 'tw-mb-1.5'}`);

const getCanShowChipActiveIcon = (option) => option.icon ? false : props.canShowChipActiveIcon;
const isActive = (option) => props.modelValue.includes(getOptionValue(option));
const isLocked = (option) => option.isAllAccess && props.isAllAccess === false;

const getCanShowIcon = (option) => {
  if (option.icon) {
    return true;
  }

  return isLocked(option) && props.canShowLockedIcon;
};

const getIconProps = (option) => {
  const iconProps = {
    class: 'tw-inline-block tw-w-4 tw-h-4 tw-align-middle',
  };

  if (isLocked(option)) {
    return {
      ...iconProps,
      style: {
        backgroundColor: 'var(--saturated-teal)',
        ...getMaskImageStyle('https://blizzard.opensnow.com/icons/custom/green-lock.svg'),
      },
    };
  }

  return {
    ...iconProps,
    style: {
      backgroundColor: 'currentColor',
      ...getMaskImageStyle(option.icon),
    },
  };
};

const getOptionLabel = (option) => typeof option === 'object' ? option?.label : option;
const getOptionValue = (option) => typeof option === 'object' ? option?.value : option;

const handleScrollActiveElementIntoView = (index) => {
  if (props.shouldScrollActiveElementIntoView && typeof index === 'number') {
    scrollActiveElementIntoView({
      parent: list.value,
      activeElement: listItems.value?.[index],
    });
  }
};

/**
 * @todo Blur button on click (likely needed as a prop or default behavior in <Button>).
 */
const handleClick = (option, optionIndex) => {
  const newValue = [...props.modelValue];
  const optionValue = getOptionValue(option);

  // Special cases for single option selectable (multiple is false):
  //
  // - When the option is already selected do nothing
  // - When a new option is selected then remove the old value

  if (props.multiple === false) {
    if (newValue.includes(optionValue)) {
      return;
    }

    newValue.splice(0, Infinity);
  }

  // Special cases for the selectAllOptionValue:
  //
  // - When the select all option value is selected then deselect all other options.
  // - When the select all option is selected and another option is selected then unselect the
  //   select all option.

  if (props.selectAllOptionValue) {
    if (optionValue === props.selectAllOptionValue) {
      newValue.splice(0, Infinity);
    }
    else if (newValue.includes(props.selectAllOptionValue)) {
      const index = newValue.findIndex((e) => e === props.selectAllOptionValue);
      newValue.splice(index, 1);
    }
  }

  // General cases:
  //
  // - Remove the option value from the new value if the option is unselected
  // - Add the option value to the new value if not already selected

  if (newValue.includes(optionValue)) {
    const index = newValue.findIndex((e) => e === optionValue);
    newValue.splice(index, 1);
  }
  else {
    newValue.push(optionValue);
  }

  $emit('update:modelValue', newValue);

  handleScrollActiveElementIntoView(optionIndex);
};

onMounted(() => {
  if (props.shouldScrollActiveElementIntoView) {
    const firstActiveOption = props.options.findIndex((option) => isActive(option));
    handleScrollActiveElementIntoView(firstActiveOption);
  }
});
</script>

<template>
  <ul
    ref="list"
    :class="listClass"
  >
    <li
      v-for="(option, index) in options"
      ref="listItems"
      :key="getOptionValue(option)"
      :class="listItemClass"
    >
      <Button
        v-bind="buttonProps"
        :active="isActive(option)"
        :disabled="isLocked(option)"
        :can-show-chip-active-icon="getCanShowChipActiveIcon(option)"
        @click="handleClick(option, index)"
      >
        <span
          v-if="getCanShowIcon(option)"
          v-bind="getIconProps(option)"
        />
        {{ getOptionLabel(option) }}
      </Button>
    </li>
  </ul>
</template>

<style module>
/**
 * Hide scrollbar yet allow content to still be scrolled!
 * SEE: https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll#answer-49278385
 * TODO: Consider moving these styles to a common location. They have been copied from
 * <SnowLocationNavigation>.
 */
.list {
  scrollbar-width: none; /* Firefox */
}

.list::-webkit-scrollbar { /* WebKit */
  height: 0;
  width: 0;
}
</style>
